import type {ReactNode} from 'react';

import {twMerge} from '@/stylesheets/twMerge';
import Typography from '@/components/base/elements/Typography/Typography';

export interface KickerProps {
  children: ReactNode;
  className?: string;
  as?: 'h1' | 'h2' | 'p';
  size?: 'base' | 'lg' | 'sm';
}

export default function Kicker({
  children,
  className,
  as = 'p',
  size = 'base',
}: KickerProps) {
  const componentName = 'kicker';
  const sizeMap: {[key: string]: string} = {
    lg: 'text-t7',
    base: 'text-t8',
    sm: 'text-sm',
  };
  const props = {
    className: twMerge(
      sizeMap[size],
      'uppercase font-bold leading-none',
      className,
    ),
    as,
    children,
    componentName,
    isRichtext: false,
  };
  return <Typography {...props} />;
}
